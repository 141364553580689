import requestTechsso from "@/utils/requestTechsso.js";

export function GetRequestOrderLst(data) {
  return requestTechsso({
    url: "TRequestOrder/TGetRequestOrderLst",
    method: "post",
    data: data,
  });
}

export function CreateRequestOrder(data) {
  return requestTechsso({
    url: "TRequestOrder/TCreateRequestOrder",
    method: "post",
    data: data,
  });
}

export function UpdateRequestOrder(data) {
  return requestTechsso({
    url: "TRequestOrder/TUpdateRequestOrder",
    method: "post",
    data: data,
  });
}
