import axios from "axios";

// const service = axios.create({
//   baseURL: "https://api.wise.com/v1/rates?source=JPY&target=VND", // url = base url + request url
//   // withCredentials: true, // send cookies when cross-domain requests
//   timeout: 20000, // request timeout
//   headers: {
//     Accept: "application/json",
//     "Content-Type": "application/json",
//     Authorization:
//       "Basic OGNhN2FlMjUtOTNjNS00MmFlLThhYjQtMzlkZTFlOTQzZDEwOjliN2UzNmZkLWRjYjgtNDEwZS1hYzc3LTQ5NGRmYmEyZGJjZA==",
//   },
// });
const service = axios.create({
  baseURL: "https://apiclient.topi.vn/api-web/", // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 20000, // request timeout
  headers: {
    Accept: "*/*",
    "Content-Type": "application/json",
  },
});

service.interceptors.response.use(
  (response) => {
    if (response.data == null) {
      response.data = {};
    }
    const res = response.data.data.Data;
    // if the custom code is not 20000, it is judged as an error.

    return res;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default service;
