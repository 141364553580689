import moment from 'moment'

export function formatDate(date) {
  if (date) {
    return moment(date).format('yyyy-MM-DD HH:mm:ss')
  } else {
    return date
  }
}
export function formatDateDDMM(date) {
  if (date) {
    return moment(date).format('DD/MM')
  } else {
    return date
  }
}
export function formatDateHHMM(date) {
  if (date) {
    return moment(date).format('HH:mm')
  } else {
    return date
  }
}
export function formatDateDisplay(date) {
  if (date) {
    return moment(date).format('HH:mm DD-MM-YYYY')
  } else {
    return date
  }
}
export function formatDateUpload(date) {
  if (date) {
    return moment(date).format('YYYY-MM-DD')
  } else {
    return date
  }
}
export function formatDateDisplayDDMMYY(date) {
  if (date) {
    return moment(date).format('DD/MM/YYYY')
  } else {
    return date
  }
}
